/*
 * FOOTER
 *************************/

.footer {
  background-color: $gray-darkest;
  background-image: url("../images/debut-dark.png");

  &-col:not(:last-child) {
    margin-bottom: 4rem;

    @media #{$small-up} {
      margin-bottom: 0;
    }
  }

  &__heading {
    color: #fff;
  }

  &__address {
    margin-bottom: 2rem;
    color: #fff;
    font-style: normal;
  }

  &__copyright {
    margin-bottom: 2rem;
    color: #fff;
    font-size: 1.2rem;
  }

  &__links {
    list-style-type: none;
  }

  &-certifications {
    @include list-inline();
    margin-bottom: 2rem;

    &__img {
      max-width: 7.5rem;
      height: auto;

      @media #{$small-up} {
        max-width: 6rem;
      }

      @media #{$medium-up} {
        max-width: 7.5rem;
      }
    }
  }

  &-social {
    margin-bottom: 0;
    color: #fff;
    line-height: 1;

    &__link {
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;
      }
    }

    &__img {
      margin-left: 0.5rem;
      vertical-align: bottom;
    }
  }
}
