/*
 * VARIABLES
 *****************************************************************************************************************************/

// Colors
$primary: #2e864e;
$primary-dark: darken($primary, 10%);
$secondary: #eb9639;
$secondary-dark: darken($secondary, 10%);
$warning: #ed143d;
$gray-darkest: #333;
$gray-dark: #555;
$gray-light: #ddd;
$gray-lightest: #eee;

// Fonts
$heading-color: $gray-darkest;
$heading-font-family: "Noto Serif", "Times", serif;
$heading-font-weight: 400;
$heading-line-height: 1.4;

$body-color: $gray-dark;
$body-font-family: "Lato", "Arial", sans-serif;
$body-font-size: 1.6rem;
$body-font-weight: 400;
$body-line-height: 1.6;

// Breakpoints
$base-up: "only screen and (min-width: 480px)";
$extra-small-up: "only screen and (min-width: 640px)";
$small-up: "only screen and (min-width: 768px)";
$medium-up: "only screen and (min-width: 992px)";
$large-up: "only screen and (min-width: 1200px)";
$extra-large-up: "only screen and (min-width: 1600px)";
