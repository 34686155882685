/*
 * EMPLOYEE
 *************************/

.employee {
  &-col {
    &:nth-child(odd) {
      @media #{$small-up} {
        clear: both;
      }

      @media #{$medium-up} {
        clear: none;
      }
    }

    &:nth-child(3n + 1) {
      @media #{$medium-up} {
        clear: both;
      }
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
    aspect-ratio: 600/899;
    margin-bottom: 1rem;
  }

  &__placeholder {
    margin-bottom: 1rem;
  }

  &__name {
    margin-bottom: 0.5rem;
  }

  &__email {
    margin-bottom: 0.5rem;
  }

  &__bio {
    margin-bottom: 0;
  }

  &__info {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 0.5rem;
    color: $body-color;
    font-family: $body-font-family;
    font-size: 1.6rem;
    font-style: italic;
    text-transform: none;
  }

  &__email {
    display: block;
    font-size: 1.2rem;
  }

  &__divider {
    width: 4rem;
    margin-bottom: 1rem;
    height: 0.2rem;
    background-color: $primary;
  }
}