/*
 * FORMS
 *************************/

// Form Control
.form {
  &__group {
    @media #{$small-up} {
      margin-bottom: 2rem;
    }
  }

  &__input {
    display: block;
    width: 100%;
    height: 4rem;
    margin-bottom: 2rem;
    border: 0.1rem solid $gray-lightest;
    border-radius: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: #fff;
    font-family: $body-font-family;
    font-size: 1.2rem;

    @media #{$small-up} {
      margin-bottom: 0;
    }

    &:focus {
      outline: 0;
      background-color: $gray-lightest;
    }

    &--textarea {
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
  }

  &__error-message {
    display: block;
    margin-top: -2rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    color: #fff;
    background-color: $warning;
    font-size: 1.2rem;

    @media #{$small-up} {
      margin: 0;
    }
  }
}
