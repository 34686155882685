/*
 * MIXINS
 *************************/

@mixin text-shadow() {
  text-shadow: 0.1rem 0.1rem 0.3rem $gray-darkest;
}

@mixin list-inline() {
  > li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 0.5rem;

      @media #{$medium-up} {
        margin-right: 1rem;
      }
    }
  }
}

@mixin background-image() {
  background-color: $gray-dark;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin btn() {
  display: inline-block;
  border: 0;
  padding: 1rem 2rem;
  color: #fff;
  background-color: $primary;
  font: {
    family: $body-font-family;
    size: 1.2rem;
  }
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 125ms ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 0;
    color: #fff;
    background-color: $secondary;
    text-decoration: none;
  }

  &:active {
    background-color: darken($secondary, 10%);
  }
}
