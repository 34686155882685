/*
 * NAV
 *************************/
.nav {
  @media #{$small-up} {
    border-bottom: 0.1rem solid $gray-lightest;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  }

  &-contact {
    display: block;
    height: 3rem;
    margin-bottom: 1rem;
    color: #fff;
    background-color: $gray-darkest;
    line-height: 3rem;

    @media #{$small-up} {
      margin-bottom: 0;
    }

    &__list {
      @include list-inline();
      font-size: 1.2rem;

      @media #{$small-up} {
        float: right;
        font-size: 1.2rem;
      }
    }

    &__link {
      color: #fff;

      &:hover,
      &:focus {
        color: $secondary;
      }
    }
  }

  &-main {
    position: relative;

    &__logo {
      display: block;
      width: 13.5rem;
      float: left;
      margin: 0 auto 1rem;

      @media #{$small-up} {
        float: left;
        width: 17rem;
        margin-bottom: 0;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }

      @media #{$medium-up} {
        width: 20rem;
      }
    }

    &__toggle {
      position: relative;
      top: 0.5rem;
      float: right;
      font-size: 0;
      width: 3rem;
      height: 3rem;
      margin: 0;
      border: 0;
      outline: 0;
      padding: 0;
      cursor: pointer;
      background-color: transparent;

      @media #{$small-up} {
        display: none;
      }
    }

    &__bar {
      display: block;
      height: 0.3rem;
      background-color: $gray-darkest;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &__list {
      display: none;
      width: 100%;
      clear: both;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;

      @media #{$small-up} {
        display: inline-block !important;
        width: auto;
        clear: none;
        @include list-inline();
        float: right;
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
        position: static;
      }

      @media #{$medium-up} {
        padding-top: 3.1rem;
        padding-bottom: 3.1rem;
      }
    }

    &__item {
      display: block;

      &:not(:last-child) {
        border-bottom: 1px solid $primary-dark;

        @media #{$small-up} {
          margin-right: 0.5rem;
          border: 0;
        }

        @media #{$medium-up} {
          margin-right: 1rem;
        }
      }
    }

    &__link {
      @include btn();
      display: block;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 1.1rem;

      @media #{$small-up} {
        padding: 0.75rem 1.5rem;
      }
    }
  }
}
