/*
 * SERVICE
 ********************/

.services {
  &-col:not(:last-child) {
    margin-bottom: 4rem;

    @media #{$small-up} {
      margin-bottom: 0;
    }
  }

  &__heading {
    margin: 0;
    border: 0.1rem solid darken($secondary, 10%);
    padding: 1rem;
    background-color: $secondary;
    font-size: 1.8rem;
  }

  &__list {
    list-style-type: none;
    background-color: #fff;
  }

  &__item {
    display: block;
    border-width: 0 0.1rem 0.1rem 0.1rem;
    border-style: solid;
    border-color: $gray-light;
    padding: 1rem 1rem 1rem 1.5rem;

    &::before {
      content: "\f058";
      margin-right: 1rem;
      color: $primary;
      font-family: "icomoon";
    }
  }
}
