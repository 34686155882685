/*
 * ELEMENTS
 *************************/

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $body-color;
  font-family: $body-font-family;
  font-size: 1.6rem;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
}

p {
  margin-bottom: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  text-transform: uppercase;
  line-height: $heading-line-height;
}

h1,
h2,
h3 {
  margin-bottom: 2rem;
}

h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2.2rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.2rem;
}

@media #{$small-up} {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.6rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2.2rem;
  }
}

@media #{$medium-up} {
  h1 {
    font-size: 4.4rem;
  }

  h2 {
    font-size: 3.2rem;
  }

  h3 {
    font-size: 2.6rem;
  }

  h4 {
    font-size: 2.2rem;
  }

  h5 {
    font-size: 1.6rem;
  }

  h6 {
    font-size: 1.2rem;
  }
}

a {
  color: $primary;
  text-decoration: none;
  transition: color 125ms ease-in-out;

  &:hover,
  &:focus {
    color: $secondary;
    text-decoration: none;
  }
}
