/*
 * SECTION
 ********************/

.section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media #{$medium-up} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &--alt {
    background-color: $gray-light;
    background-image: url("../images/fabric-plaid.png");
  }

  &__label {
    margin-bottom: 1rem;
    color: $primary;
    font-family: $body-font-family;
    font-size: 1.6rem;
    text-transform: uppercase;
  }

  &__heading {
    font-family: $heading-font-family;
    font-size: 2.4rem;
    text-transform: uppercase;

    @media #{$small-up} {
      font-size: 2.6rem;
    }

    @media #{$medium-up} {
      font-size: 3.2rem;
    }
  }

  &__divider {
    width: 10rem;
    height: 0.4rem;
    margin-bottom: 2rem;
    background-color: $primary;
  }

  &__intro {
    font-size: 1.8rem;
  }
}
