@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?fw13xe");
  src: url("../fonts/icomoon.eot?fw13xe#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?fw13xe") format("truetype"),
    url("../fonts/icomoon.woff?fw13xe") format("woff"),
    url("../fonts/icomoon.svg?fw13xe#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check-circle:before {
  content: "\f058";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-linkedin-square:before {
  content: "\f08c";
}
