/*
 * CONTACT
 *************************/

.contact {
  background-position-y: bottom;
  @include background-image();
  background-image: url("../images/skyline.webp");

  .section__heading,
  .section__intro {
    color: #fff;
  }
}
