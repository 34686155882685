/*
 * HERO
 *************************/
.hero {
  position: relative;
  height: 25rem;
  @include background-image();
  background-image: url("../images/ledgers-team-vertical.jpg");
  background-position-y: 50%;

  @media #{$base-up} {
    height: 30rem;
  }

  @media #{$extra-small-up} {
    height: 35rem;
  }

  @media #{$small-up} {
    background-position-y: 0;
    background-image: url("../images/ledgers-team-horizontal.jpg");
  }

  @media #{$medium-up} {
    height: 60rem;
  }

  @media #{$large-up} {
    height: 75rem;
  }

  @media #{$extra-large-up} {
    height: 80rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.75));
  }

  &__content {
    position: relative;
    top: 90%;
    transform: translateY(-90%);
    text-align: center;

    @media #{$small-up} {
      top: 65%;
      transform: translateY(-65%);
    }

    @media #{$medium-up} {
      top: 55%;
      transform: translateY(-55%);
    }

    @media #{$large-up} {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__heading {
    @include text-shadow();
    margin-bottom: 0;
    font-size: 2rem;
    color: #fff;

    @media #{$base-up} {
      font-size: 2.4rem;
    }

    @media #{$small-up} {
      font-size: 3rem;
    }

    @media #{$medium-up} {
      font-size: 4.4rem;
    }
  }

  &__highlight {
    color: $primary;
  }
}