.responsive-embed {
  height: 0;
  overflow: hidden;
  padding-bottom: 100vh;
  position: relative;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
