.placeholder {
    position: relative;
    width: 100%;
    padding-bottom: 149.83%;
    background-color: #c49e78;
    background-image: linear-gradient(155deg, rgba(#c49e78, 0.5), rgba(#8f858a, 0.5), rgba(#6e8466, 0.5));

    &--alternate {
        background-image: linear-gradient(155deg, rgba(#6e8466, 0.5), rgba(#c49e78, 0.5), rgba(#8f858a, 0.5));
    }

    &__contents {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}