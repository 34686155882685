.avatar {
    width: 10rem;
    height: 10rem;
    border: 3px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__initials {
        font-size: 3.2rem;
        color: #fff;
        margin: 0;
    }
}